import React, { useRef } from "react";

// react-bootstrap components
import NotificationAlert from "react-notification-alert";
import { variables } from "helpers/Variables";
import {
  Button,
  Card,
  Modal,
  Form,
  Table,
  Container,
  Row,
  Col,
  Tooltip,
  ListGroup,
  Spinner,
  OverlayTrigger,
} from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";
import { useHistory } from "react-router-dom";
import { DownloadTableExcel } from "react-export-table-to-excel";
import InputMask from "react-input-mask";
import moment from "moment";

function BookmarkCheckFill() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className="bi bi-bookmark-check-fill bookmark-check-fill"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zm8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"
      />
    </svg>
  );
}

function UyeTalep() {
  const history = useHistory();

  const [egitimId, setegitimId] = React.useState(0);
  const [guncelle, setGuncelle] = React.useState(false);
  //const [detayRow, setDetayRow] = React.useState([]);
  const [detay, setDetay] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [donusTipleri, setDonusTipleri] = React.useState([
    { key: 1, val: "Memnuniyet" },
    { key: 2, val: "Talep" },
    { key: 3, val: "Şikayet(düşük)" },
    { key: 4, val: "Şikayet(normal)" },
    { key: 5, val: "Şikayet(yüksek)" },
    { key: 6, val: "Öneri" },
    { key: 7, val: "Bilgi" },
  ]);
  
  const [bildirimDurumlari, setbildirimDurumlari] = React.useState([
    { key: 1, val: "Geri Bildirim Alındı" },
    { key: 2, val: "İşlem Devam Ediyor" },
    { key: 3, val: "İşlem Beklemede" },
    { key: 4, val: "Yöneticide" },
    { key: 5, val: "DF Açıldı" },
    { key: 6, val: "Mutabakat Sağlanamadı" },
    { key: 7, val: "Dış Kaynaklara Yönlendirildi" },
    { key: 8, val: "Kapatıldı" },
    { key: 9, val: "Sonuçlandırıldı" },
  ]); 
  const geriBildirimKanali = [
    { key: 1, val: "ÜYE ZİYARETİ" },
    { key: 2, val: "HİZMET ALIMI" },
    { key: 3, val: "WEB SAYFASI" },
    { key: 4, val: "E-MAİL" },
    { key: 5, val: "TELEFON" },
    { key: 6, val: "SOSYAL MEDYA" },
    { key: 7, val: "DİĞER" },
  ];
  const geriBildirimKaynagi = [
    { key: 1, val: "YÖNETİMDEN KAYNAKLI" },
    { key: 2, val: "PERSONELDEN KAYNAKLI" },
    { key: 3, val: "ÇALIŞMA ORTAMI VE ALT YAPI KAYNAKLI" },
    { key: 4, val: "HİZMET KALİTESİ KAYNAKLI" },
    { key: 5, val: "FİNANSAL DURUM KAYNAKLI" },
    { key: 6, val: "ETKİNLİK(EĞİTİM, FUAR VB.) KAYNAKLI" },
    { key: 7, val: "LOBİ FAALİYETİ KAYNAKLI" },
  ];
  const geriBildirimTuru = [
    { key: 1, val: "ŞİKAYET" },
    { key: 2, val: "TALEP" },
    { key: 3, val: "ÖNERİ" },
  ];
  const geriBildirimOnceligi = [
    { key: 1, val: "ACİL/YÜKSEK" },
    { key: 2, val: "NORMAL" },
    { key: 3, val: "DÜŞÜK" },
  ];
  const geriBildirimArastirilmasiveCozumunSaglanmasi = [
    { key: 1, val: "GERİ BİLDİRİMİN ARAŞTIRILMASI VE ÇÖZÜLMESİ" },
    { key: 2, val: "DÜZELTME FAALİYETİ" },
  ];
  const cozumunGeriBildirimSahibineBildirilmesi = [
    { key: 1, val: "ÇÖZÜMÜN BİLDİRİLMESİ" },
    { key: 2, val: "DÜZELTME FAALİYETİNİN BİLDİRİLMESİ" },
  ];
  const geriBildirimSahibininCozumSurecindenMemnuniyeti = [
    { key: 1, val: "İYİ" },
    { key: 2, val: "NORMAL" },
    { key: 3, val: "KÖTÜ" },
  ];
  const geriBildirimDurumu = [
    { key: 1, val: "SONUÇLANDIRILDI" },
    { key: 2, val: "KAPATILDI" },
  ];
  const initialForm = {
    DonusTipi: "0",
    Kisi: "",
    Mail: "",
    Personel: 0,
    OdaSicil: "",
    Tarih: new Date().toJSON(),
    GeriBildirimKanali: "geriBildirimKanali",
    GeriBildirimKaynagi: "geriBildirimKaynagi",
    GeriBildirimTuru: "geriBildirimTuru",
    GeriBildirimOnceligi: "geriBildirimOnceligi",
    GeriBildirimAlindiTarih: null,
    Tel: "",
  };
  const detayRow1initial={
    XKey: "GERİ BİLDİRİMİN ARAŞTIRILMASI VE ÇÖZÜMÜNÜN SAĞLANMASI",
    XValue: "-",
  };
  const detayRow2initial={
    XKey: "ÇÖZÜMÜN GERİ BİLDİRİM SAHİBİNE BİLDİRİLMESİ",
    XValue: "-",
  };
  const detayRow3initial={
    XKey: "GERİ BİLDİRİM SAHİBİNİN ÇÖZÜM SÜRECİNDEN MEMNUNİYETİ",
    XValue: "-",
    AlinanAksiyon:"-",
  };
  const detayRow4initial={
    XKey: "GERİ BİLDİRİM DURUMU",
    XValue: "-",
    AlinanAksiyon:"-",
  };
  const [detayRow, setDetayRow] = React.useState([]);
  const [detayRow1, setDetayRow1] = React.useState(detayRow1initial);
  const [detayRow2, setDetayRow2] = React.useState(detayRow2initial);
  const [detayRow3, setDetayRow3] = React.useState(detayRow3initial);
  const [detayRow4, setDetayRow4] = React.useState(detayRow4initial); 
  const yesilDurumlar = [7, 8, 9];
  const yesilDonusTipDurumlar = [1];
  const [form, setForm] = React.useState(initialForm);
  const [filtre, setFiltre] = React.useState({
    "YilBas": (new Date().getFullYear()), "YilBit": (new Date().getFullYear()),
    tad: "GetUyeTalepv3",
  });
  const fillSelect = (arr) => {
    var indents = [];
    indents.push(
      <option key={0} value={0}>
        SEÇİNİZ
      </option>
    );
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      indents.push(
        <option key={element.key} value={element.val}>
          {element.val}
        </option>
      );
    }
    return indents;
  };
  const fillSelectv2 = (arr) => {
    var indents = [];
    indents.push(
      <option key={0} value={0}>
        SEÇİNİZ
      </option>
    );
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      indents.push(
        <option key={element.key} value={element.key}>
          {element.val}
        </option>
      );
    }
    return indents;
  };
  const [showModal, setShowModal] = React.useState(false);
  const [atananPersonel, setatananPersonel] = React.useState([]);
  // const [showPersonelModal, setshowPersonelModal] = React.useState(false);
  const tableRef = useRef(null);

  const token = secureLocalStorage.getItem("token"); 

  const egitimePersonelCikart = (elem) => {
    let tmp = structuredClone(atananPersonel);
    for (let index = 0; index < tmp.length; index++) {
      const element = tmp[index];
      if (elem.k_no === element.k_no) {
        tmp.splice(index, 1);
      }
    }
    setatananPersonel(tmp);
  };
  const notificationAlertRef = React.useRef(null);
  const notify = (place, textt, typee) => {
    var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{textt}</div>
        </div>
      ),
      type: typee ?? type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  // const getData = async () => {
  //     const istek = await fetch(variables.API_URL + 'page/GetUyeTalep', {
  //         method: 'get',
  //         headers: { 'Tad': 'UyeTalep', 'Accept': 'application/json, text/plain, */*', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
  //     })
  //     if (await istek.ok) {
  //         setData(await istek?.json());
  //     } else {
  //         let txt = await istek.text();
  //         alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
  //         history.push("/admin/giris");
  //     }
  // }
  const getDataFiltered = async () => {
    // const ff = structuredClone(filtre);
    // console.log(ff);
    (async () => {
      try {
        const istek = await fetch(variables.API_URL + "Page/", {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            Tad: "GetUyeTalepv2",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(filtre),
        });
        setData(await istek?.json());
      } catch (error) {
        let txt = await istek.text();
        alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
        history.push("/admin/giris");
      }

      //const content = await rawResponse.json();
    })();
  };
  const addFormData = (key, value) => {
    const ff = structuredClone(form);
    ff[key] = value;
    if (key == "GeriBildirimAlindiTarih") {
      value = moment(value).format("yyyy-MM-DD HH:mm:ss");
      ff[key] = value;
    }
    setForm(ff);
  };
//   const talepDetayGetir = (talepId) => {
//     (async () => {
//       const istek = await fetch(variables.API_URL + "Page", {
//         method: "post",
//         headers: {
//           Accept: "application/json, text/plain, */*",
//           "Content-Type": "application/json",
//           Tad: "GetUyeTalepDetay",
//           Authorization: "Bearer " + token,
//         },
//         body: JSON.stringify({ uyetalepid: talepId, tad: "GetUyeTalepDetay" }),
//       });
//       if (await istek.ok) {
//         //console.log(await istek?.json());
//         setDetay(await istek?.json());
//       } else {
//         let txt = await istek.text();
//         alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
//       }
//     })();
//   };
  const duzenle = (elem) => {
    
    talepDetayGetir(elem.Id);
    setGuncelle(true);
    setShowModal(true);
    setForm(elem);
    //console.log(elem);
  };

  const formKaydet = () => {
    // console.log(form, "form");
    const ff = structuredClone(form);
    //ff.Id = 0;
    ff.tad = "InsertUyeTalep";
    (async () => {
      const rawResponse = await fetch(variables.API_URL + "Page/", {
        method: "POST",
        // headers: { 'Content-Type': 'application/json' },
        headers: {
          Accept: "application/json, text/plain, */*",
          Tad: "InsertUyeTalep",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(ff),
        //body: ff
      });
      const content = await rawResponse.json();
      // console.log(content);
      modalKapat();
      // getData();
      getDataFiltered();
      notify("tc", "Kayıt işlemi başarılı", "success");
    })();
  };
  const formGuncelle = () => {
    const ff ={"Id":form.Id,"Kisi":form.Kisi,"Aciklama":form.Aciklama
      ,"OdaSicil":form.OdaSicil,"Tel":form.Tel
      ,"GeriBildirimAlindiTarih":form.GeriBildirimAlindiTarih
      ,"GeriBildirimKanali":form.GeriBildirimKanali
      ,"GeriBildirimKaynagi":form.GeriBildirimKaynagi
      ,"GeriBildirimOnceligi":form.GeriBildirimOnceligi
      ,"GeriBildirimTuru":form.GeriBildirimTuru
      ,"Mail":form.Mail
    }
    
    ff.tad = "UyeTalep";
    //console.log(ff);return;
    (async () => {
      const rawResponse = await fetch(variables.API_URL + "page", {
        method: "put",
        headers: {
          Accept: "application/json, text/plain, */*",
          Tad: "UyeTalep",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(ff),
        //body: form
      });
      const content = await rawResponse.json();
      modalKapat();
      //getData();
      getDataFiltered();
      notify("tc", "Güncelleme işlemi başarılı", "success");
    })();
  };
  const formSil = (id) => {
    if (confirm("Silmek istediğinizden emin misiniz?")) {
      // console.log(id, "id");
      //const ff = structuredClone(form);
      const rr = {};
      rr.tad = "UyeTalep";
      rr.Id = id;
      (async () => {
        const rawResponse = await fetch(variables.API_URL + "Page/", {
          method: "DELETE",
          headers: {
            Accept: "application/json, text/plain, */*",
            Tad: "UyeTalep",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          //headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(rr),
          //body: form
        });
        //const content = await rawResponse.json();
        modalKapat();
        //getData();
        getDataFiltered();
        notify("tc", "Silme işlemi başarılı", "success");
      })();
    }
  };
  const detaySil = (id) => {
    if (confirm("Silmek istediğinizden emin misiniz?")) {
      (async () => {
        const rawResponse = await fetch(variables.API_URL + "Page/", {
          method: "DELETE",
          headers: {
            Accept: "application/json, text/plain, */*",
            Tad: "UyeTalepDetay",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({ Id: id, tad: "UyeTalepDetay" }),
          //body: form
        });
        //const content = await rawResponse.json();
        //modalKapat();
        //getData();
        talepDetayGetir(form?.Id);
        notify("tc", "Silme işlemi başarılı", "success");
      })();
    }
  };
//   const detaySil = (id) => {
//     if (confirm("Silmek istediğinizden emin misiniz?")) {
//       (async () => {
//         const rawResponse = await fetch(variables.API_URL + "Page/", {
//           method: "DELETE",
//           headers: {
//             Accept: "application/json, text/plain, */*",
//             Tad: "UyeTalepDetay",
//             "Content-Type": "application/json",
//             Authorization: "Bearer " + token,
//           },
//           body: JSON.stringify({ Id: id, tad: "UyeTalepDetay" }),
//           //body: form
//         });
//         //const content = await rawResponse.json();
//         //modalKapat();
//         //getData();
//         getDataFiltered();
//         notify("tc", "Silme işlemi başarılı", "success");
//       })();
//     }
//   };
  const keyUpSearch = (e, refn) => {
    let filter = e.target.value.toLowerCase();
    if (refn == "scroller1") {
      let nodes = document.getElementsByClassName("scroller1");
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].innerText.toLowerCase().includes(filter)) {
          nodes[i].style.display = "block";
        } else {
          nodes[i].style.display = "none";
        }
      }
    }
    if (refn == "scroller2") {
      let nodes = document.getElementsByClassName("scroller2");
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].innerText.toLowerCase().includes(filter)) {
          nodes[i].style.display = "block";
        } else {
          nodes[i].style.display = "none";
        }
      }
    }
  };
  const fillDonusTipiSelect = () => {
    var indents = [];
    indents.push(
      <option key={0} value={0}>
        SEÇİNİZ
      </option>
    );
    for (let i = 0; i < donusTipleri.length; i++) {
      const element = donusTipleri[i];
      indents.push(
        <option key={element.key} value={element.key}>
          {element.val}
        </option>
      );
    }
    return indents;
  };
  const fillBildirimDurumuSelect = () => {
    var indents = [];
    indents.push(
      <option key={0} value={0}>
        SEÇİNİZ
      </option>
    );
    for (let i = 0; i < bildirimDurumlari.length; i++) {
      const element = bildirimDurumlari[i];
      indents.push(
        <option key={element.key} value={element.key}>
          {element.val}
        </option>
      );
    }
    return indents;
  };
  const getYears = () => {
    var indents = [];
    for (var i = (new Date().getFullYear()); i > 2008; i--) {
        indents.push(<option key={i} value={i}>{i}</option>);
    }
    return indents;
}

const buyil = getYears();
  const fillYearsSelect = () => {
    var indents = [];
    indents.push(
      <option key={0} value={0}>
        SEÇİNİZ
      </option>
    );
    for (let i = 0; i < bildirimDurumlari.length; i++) {
      const element = bildirimDurumlari[i];
      indents.push(
        <option key={element.key} value={element.key}>
          {element.val}
        </option>
      );
    }
    return indents;
  };
  function FormBosalt() {
    //console.log(112)
    setForm(initialForm);
  }
  function personelModalKapat() {
    //setshowPersonelModal(false);
    setForm(initialForm);
    setatananPersonel([]);
    // setGuncelle(false);
  }
  function modalKapat() {
    setShowModal(false);
    setGuncelle(false);
    //setForm({});
  }
  const satirEkle = (arr) => {
    const dt = structuredClone(arr);
    dt.UyeTalepId = form.Id;
    dt.tad = "PostUyeTalepDetay";
    //console.log(dt);return;
    (async () => {
      const istek = await fetch(variables.API_URL + "Page", {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Tad: dt.tad,
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(dt),
      });
      if (await istek.ok) {
        //console.log("talepDetayGetir");
        talepDetayGetir(form.Id);
      } else {
        let txt = await istek.text();
        alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
      }
    })();
  };
//   const satirEkle = () => {
//     const dt = structuredClone(detayRow);
//     dt.UyeTalepId = form.Id;
//     dt.tad = "PostUyeTalepDetay";
//     //console.log(dt);
//     (async () => {
//       const istek = await fetch(variables.API_URL + "Page", {
//         method: "post",
//         headers: {
//           Accept: "application/json, text/plain, */*",
//           "Content-Type": "application/json",
//           Tad: "PostUyeTalepDetay",
//           Authorization: "Bearer " + token,
//         },
//         body: JSON.stringify(dt),
//       });
//       if (await istek.ok) {
//         //console.log(await istek?.json());
//         //setDetay(await istek?.json());
//         talepDetayGetir(form.Id);
//         notify("tc", "Kayıt işlemi başarılı", "success");
//       } else {
//         let txt = await istek.text();
//         alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
//       }
//     })();
//   };


  // React.useEffect(() => {
  //     //getData()
  //     getDataFiltered();
  // }, [])
  // React.useEffect(() => {
  //     console.log(`data`, data);
  // }, [data])
  const talepDetayGetir = (talepId) => {
    //return;
    (async () => {
      const istek = await fetch(variables.API_URL + "Page", {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Tad: "GetUyeTalepDetay",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ uyetalepid: talepId, tad: "GetUyeTalepDetay" }),
      });
      if (await istek.ok) {
        //console.log(await istek?.json());
        setDetayRow(await istek?.json());
      } else {
        let txt = await istek.text();
        alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
      }
    })();
  }; 
  React.useEffect(() => {
    getDataFiltered();
  }, [filtre]);
  // React.useEffect(() => {
  //   console.log(detayRow4);
  // }, [detayRow4]);
  // React.useEffect(() => {
  //   console.log(data);
  // }, [data]);
  // React.useEffect(() => {
  //   console.log(form);
  // }, [form]);
  return (
    <>
      <Container fluid>
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                {/* <Card.Title as="h4">Üye Bildirim</Card.Title> */}
                {/* <Button onClick={() => setShowModal(true)}>EKLE</Button> */}
                <Button variant="secondary">Üye Bildirim</Button>
                <DownloadTableExcel
                  filename="ÜyeBildirimTablo"
                  sheet="ÜyeBildirim"
                  currentTableRef={tableRef.current}
                >
                  <Button variant="secondary"> Excel'e aktar </Button>
                </DownloadTableExcel>
                <span> {data?.length + " adet kayıt bulundu!"}</span>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Başlangıç:</b>
                      </Form.Label>
                      <Form.Select
                        className="form-control"
                        aria-label="select"
                        value={filtre?.YilBas}
                        onChange={(e) => {
                          setFiltre({
                            ...filtre,
                            YilBas: e.target.value,
                          });
                        }}
                      >
                        {buyil}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Bitiş:</b>
                      </Form.Label>
                      <Form.Select
                        className="form-control"
                        aria-label="select"
                        value={filtre?.YilBit}
                        onChange={(e) => {
                          setFiltre({
                            ...filtre,
                            YilBit: e.target.value,
                          });
                        }}
                      >
                        {buyil}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="overflow-x-scroll">
                  {/* <Table ref={tableRef} className="table-hover table-striped uyeTalepTable table-layout-fixed table-td-width-auto"> */}
                  <Col>
                    <Table
                      ref={tableRef}
                      className="table-hover table-striped uyeTalepTable table-td-width-auto  "
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          {/* <th>Aks.Tarih</th>
                          <th className="w20percentXXX">Aksiyon</th> */}
                          <th>Oda Sicil</th>
                          <th>Görüşülen</th>
                          <th className="w20percentXXX  ">Açıklama</th>
                          <th>Tel</th>
                          <th>Mail</th>
                          <th>Kayıt Tarihi</th>
                          <th>Geri Bildirimin İşleme Alındığının Bildirilme Tarihi</th>
                          <th>Geri Bildirim Kanalı</th>
                          <th>Geri Bildirim Kaynağı</th>
                          <th>Geri Bildirim Türü</th>
                          <th>Geri Bildirim Önceliği</th>
                          <th>Personel</th>
                          <th>GERİ BİLDİRİMİN ARAŞTIRILMASI VE ÇÖZÜLMESİ TARİHİ</th>
                          <th>GERİ BİLDİRİMİN ARAŞTIRILMASI VE ÇÖZÜLMESİ AÇIKLAMASI</th>
                          <th>Düzeltme Faaliyeti Tarihi</th>
                          <th>Düzeltme Faaliyeti Açıklama</th>
                          <th>Çözümün Bildirilmesi Tarihi</th>
                          <th>Çözümün Bildirilmesi Açıklama</th>
                          <th>Düzeltme Faaliyetinin Bildirilmesi Tarihi</th>
                          <th>Düzeltme Faaliyetinin Bildirilmesi Açıklama</th>
                          <th>Bildirim Sahibinin Çözümden Memnuniyeti Tarihi</th>
                          <th>Bildirim Sahibinin Çözümden Memnuniyeti Açıklama</th>
                          <th>Geri Bildirim Sonuçlandırıldı Tarihi</th>
                          <th>Geri Bildirim Sonuçlandırıldı Açıklama</th>
                          <th>Geri Bildirim Kapatildi Tarihi</th>
                          <th>Geri Bildirim Kapatildi Açıklama</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data != undefined > 0
                          ? data?.map((elem, index) => {
                              return (
                                <tr
                                  key={index}
                                  className={
                                    elem?.GeriBildirimSonuclandirildiTarih !=null ||elem?.GeriBildirimKapatildiTarih !=null 
                                      ? "bgGreen"
                                      : "bgRed"
                                  }
                                >
                                  <td>
                                    {elem.Id}{" "}
                                    <br/>
                                    <span
                                      title={"Güncelle/Düzenle"}
                                      className="cursor colorGreen"
                                    >
                                      <svg
                                        onClick={() => {
                                          duzenle(elem);
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-pencil-square"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                        <path
                                          fillRule="evenodd"
                                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                        />
                                      </svg>
                                    </span>
                                    <span
                                      title={"Sil!"}
                                      className="cursor colorRed"
                                    >
                                      <svg
                                        onClick={() => {
                                          formSil(elem.Id);
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-trash"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                        <path
                                          fillRule="evenodd"
                                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                        />
                                      </svg>
                                    </span>
                                  </td>
                                  {/* <td>
                                    {elem.AksiyonTarih != ""
                                      ? elem.AksiyonTarih?.split("T")[0]
                                      : "-"}
                                  </td>
                                  <td className=" ">
                                    <OverlayTrigger
                                      key={"left"}
                                      placement={"left"}
                                      overlay={
                                        <Tooltip id={`tooltip-${"left"}`}>
                                          {elem.AlinanAksiyon != ""
                                            ? elem.AlinanAksiyon
                                            : "-"}
                                        </Tooltip>
                                      }
                                    >
                                      <div className=" dotdotdot">
                                        {elem.AlinanAksiyon != ""
                                          ? elem.AlinanAksiyon
                                          : "-"}
                                      </div>
                                    </OverlayTrigger>
                                  </td> */}
                                  <td>
                                    <span title={"Oda Sicil"}>
                                      {elem.OdaSicil}
                                    </span>
                                  </td>
                                  <td>
                                    <span title={"Görüşülen Kişi"}>
                                      {elem.Kisi}
                                    </span>
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      key={"left"}
                                      placement={"left"}
                                      overlay={
                                        <Tooltip id={`tooltip-${"left"}`}>
                                          {elem.Aciklama}
                                        </Tooltip>
                                      }
                                    >
                                      <div className=" dotdotdot">
                                        {elem.Aciklama}
                                      </div>
                                    </OverlayTrigger>
                                  </td>
                                  <td>{elem.Tel}</td>
                                  <td>{elem.Mail}</td>
                                  <td>{elem.Tarih?.split("T")[0]}</td>
                                  <td>{elem.GeriBildirimAlindiTarih?.replace("T"," ")}</td>
                                  <td>{elem?.GeriBildirimKanaliText}</td>
                                  <td>{elem?.GeriBildirimKaynagiText}</td>
                                  <td>{elem?.GeriBildirimTuruText}</td>
                                  <td>{elem?.GeriBildirimOnceligiText}</td>
                                  <td className="  ">
                                    <OverlayTrigger
                                      key={"left"}
                                      placement={"left"}
                                      overlay={
                                        <Tooltip id={`tooltip-${"left"}`}>
                                          {elem.Personel}
                                        </Tooltip>
                                      }
                                    >
                                      <div className=" dotdotdot w50px">
                                        {elem.Personel}
                                      </div>
                                    </OverlayTrigger>
                                  </td>
                                  <td className="">{elem?.GBAVCTarih?.split("T")[0]}</td>
                                  <td className="">
                                    <OverlayTrigger
                                        key={"left"}
                                        placement={"left"}
                                        overlay={
                                          <Tooltip id={`tooltip-${"left"}`}>
                                            {elem?.GBAVCAciklama}
                                          </Tooltip>
                                        }
                                      >
                                      <div className=" dotdotdot w50px">
                                        {elem?.GBAVCAciklama}
                                      </div>
                                    </OverlayTrigger>
                                  </td>
                                  <td className="">{elem?.DuzeltmeFaaliyetiTarih?.split("T")[0]}</td>
                                  <td className="">
                                    <OverlayTrigger
                                        key={"left"}
                                        placement={"left"}
                                        overlay={
                                          <Tooltip id={`tooltip-${"left"}`}>
                                            {elem?.DuzeltmeFaaliyetiAciklama}
                                          </Tooltip>
                                        }
                                      >
                                      <div className=" dotdotdot w50px">
                                        {elem?.DuzeltmeFaaliyetiAciklama}
                                      </div>
                                    </OverlayTrigger>
                                  </td>
                                  <td className="">{elem?.CozumunBildirilmesiTarih?.split("T")[0]}</td>
                                  <td className="">
                                    <OverlayTrigger
                                        key={"left"}
                                        placement={"left"}
                                        overlay={
                                          <Tooltip id={`tooltip-${"left"}`}>
                                            {elem?.CozumunBildirilmesiAciklama}
                                          </Tooltip>
                                        }
                                      >
                                      <div className=" dotdotdot w50px">
                                      {elem?.CozumunBildirilmesiAciklama}
                                      </div>
                                    </OverlayTrigger>
                                  </td>
                                  <td className="">{elem?.DuzeltmefaaliyetininBildirilmesiTarih?.split("T")[0]}</td>
                                  <td className="">
                                    <OverlayTrigger
                                        key={"left"}
                                        placement={"left"}
                                        overlay={
                                          <Tooltip id={`tooltip-${"left"}`}>
                                            {elem?.DuzeltmefaaliyetininBildirilmesiAciklama}
                                          </Tooltip>
                                        }
                                      >
                                      <div className=" dotdotdot w50px">
                                      {elem?.DuzeltmefaaliyetininBildirilmesiAciklama}
                                      </div>
                                    </OverlayTrigger>
                                  </td>
                                  <td className="">{elem?.BildirimSahibininCozumdenMemnuniyetiTarih?.split("T")[0]}</td>
                                  <td className="">
                                    <OverlayTrigger
                                        key={"left"}
                                        placement={"left"}
                                        overlay={
                                          <Tooltip id={`tooltip-${"left"}`}>
                                            {elem?.BildirimSahibininCozumdenMemnuniyetiAciklama}
                                          </Tooltip>
                                        }
                                      >
                                      <div className=" dotdotdot w50px">
                                      {elem?.BildirimSahibininCozumdenMemnuniyetiAciklama}
                                      </div>
                                    </OverlayTrigger>
                                  </td>
                                  <td className="">{elem?.GeriBildirimSonuclandirildiTarih?.split("T")[0]}</td>
                                  <td className="">
                                    <OverlayTrigger
                                        key={"left"}
                                        placement={"left"}
                                        overlay={
                                          <Tooltip id={`tooltip-${"left"}`}>
                                            {elem?.GeriBildirimSonuclandirildiAciklama}
                                          </Tooltip>
                                        }
                                      >
                                      <div className=" dotdotdot w50px">
                                      {elem?.GeriBildirimSonuclandirildiAciklama}
                                      </div>
                                    </OverlayTrigger>
                                  </td>
                                  <td className="">{elem?.GeriBildirimKapatildiTarih?.split("T")[0]}</td>
                                  <td className="">
                                    <OverlayTrigger
                                        key={"left"}
                                        placement={"left"}
                                        overlay={
                                          <Tooltip id={`tooltip-${"left"}`}>
                                            {elem?.GeriBildirimKapatildiAciklama}
                                          </Tooltip>
                                        }
                                      >
                                      <div className=" dotdotdot w50px">
                                      {elem?.GeriBildirimKapatildiAciklama}
                                      </div>
                                    </OverlayTrigger>
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          {showModal ? (
            <Modal
              size="xl"
              show={true}
              backdrop="static"
              onHide={() => modalKapat()}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Geri Bildirim Ekle
                  <OverlayTrigger
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>Formu Boşalt</Tooltip>
                    }
                  >
                    <svg
                      onClick={(e) => FormBosalt()}
                      title="Formu Boşalt"
                      width="22"
                      height="22"
                      fill="currentColor"
                      className="bi bi-arrow-clockwise"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                      />
                      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                    </svg>
                  </OverlayTrigger>
                  <OverlayTrigger
                      key={"left"}
                      placement={"left"}
                      overlay={
                        <Tooltip id={`tooltip-${"left"}`}>
                          {form?.OdaSicilUnvan}
                        </Tooltip>
                      }
                    >
                      <small className="dotdotdotMini"> {form?.OdaSicilUnvan!=null?'Ünvan:'+form?.OdaSicilUnvan:''}</small>
                    </OverlayTrigger>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form as={Row} id="formUyeTalepEkle" className="was-validated">
                  <Form.Group as={Col} md="4" className="mb-3">
                    <Form.Label>Geri Bildirim Tarihi:</Form.Label>
                    <Form.Control
                      type={"date"}
                      required
                      value={form?.Tarih?.split("T")[0]}
                      onChange={(e) => addFormData("Tarih", e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="4" className="mb-3">
                    <Form.Label>Oda Sicil:
                    
                      
                    </Form.Label>
                    <Form.Control
                      type="text"
                      required
                      value={form?.OdaSicil}
                      onChange={(e) => addFormData("OdaSicil", e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4" className="mb-3">
                    <Form.Label>İletişim Ad Soyad:</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      value={form?.Kisi}
                      onChange={(e) => addFormData("Kisi", e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="4" className="mb-3">
                    <Form.Label>Telefon:</Form.Label>
                    <InputMask
                      value={form?.Tel}
                      onChange={(e) => addFormData("Tel", e.target.value)}
                      required
                      className={`form-control ${
                        form?.Tel?.length > 8 && !isNaN(+form?.Tel)
                          ? " bordervalid "
                          : " borderinvalid "
                      }`}
                      mask="\5999999999"
                      maskChar="_"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4" className="mb-3">
                    <Form.Label>Mail:</Form.Label>
                    <Form.Control
                      type="mail"
                      required
                      value={form?.Mail}
                      onChange={(e) => addFormData("Mail", e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4" className="mb-3">
                    <Form.Label>Geri Bildirim Kanalı:</Form.Label>
                    <Form.Select
                      className="form-control"
                      aria-label="select"
                      // value={form?.DonusTipi}
                      value={form?.GeriBildirimKanali}
                      onChange={(e) => {
                        addFormData("GeriBildirimKanali", e.target.value);
                      }}
                    >
                      {fillSelectv2(geriBildirimKanali)}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} md="4" className="mb-3">
                    <Form.Label>Geri Bildirim Kaynağı:</Form.Label>
                    <Form.Select
                      className="form-control"
                      aria-label="select"
                      value={form?.GeriBildirimKaynagi}
                      onChange={(e) => {
                        addFormData("GeriBildirimKaynagi", e.target.value);
                      }}
                    >
                      {fillSelectv2(geriBildirimKaynagi)}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group as={Col} md="4" className="mb-3">
                    <Form.Label>Geri Bildirim Türü:</Form.Label>
                    <Form.Select
                      className="form-control"
                      aria-label="select"
                      value={form?.GeriBildirimTuru}
                      onChange={(e) => {
                        addFormData("GeriBildirimTuru", e.target.value);
                      }}
                    >
                      {fillSelectv2(geriBildirimTuru)}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} md="4" className="mb-3">
                    <Form.Label>Geri Bildirim Önceliği:</Form.Label>
                    <Form.Select
                      className="form-control"
                      aria-label="select"
                      value={form?.GeriBildirimOnceligi}
                      onChange={(e) => {
                        addFormData("GeriBildirimOnceligi", e.target.value);
                      }}
                    >
                      {fillSelectv2(geriBildirimOnceligi)}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} md="12" className="mb-3">
                    <Form.Label>Geri Bildirim Metni:</Form.Label>
                    <textarea
                      className="form-control"
                      required
                      value={form?.Aciklama}
                      onChange={(e) => addFormData("Aciklama", e.target.value)}
                    ></textarea>
                  </Form.Group>
                  {guncelle ? (
                    <Container>
                      <Row>
                        <Col>
                        <Form.Group as={Col} md="12" className="mb-3">
                          <Form.Label>
                            Geri Bildirimin İşleme Alındığının Bildirilme
                            Zamanı:
                          </Form.Label>
                          <Form.Control
                            id="GeriBildirimAlindiTarih"
                            name="GeriBildirimAlindiTarih"
                            type={"datetime-local"}
                            pattern="^(?:199[0-9]|20[0-9][0-9])-(?:0[1-9]|1[0-2])-(?:[0-2][0-9]|3[0-1]) (?:[0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$"
                            required
                            value={form?.GeriBildirimAlindiTarih}
                            onChange={(e) =>
                              addFormData(
                                "GeriBildirimAlindiTarih",
                                e.target.value
                              )
                            }
                          />
                        </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group as={Col} md="12" className="mb-3">
                          <Form.Label>
                            Kayıt Yapan Personel:
                          </Form.Label>
                          <Form.Control
                            id="GeriBildirimAlindiTarih"
                            name="GeriBildirimAlindiTarih"
                            type={"text"}
                            value={form?.Personel}
                          />
                        </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="col-md-12">
                          <Card>
                            <Card.Body>
                              <Card.Title>
                                <strong>
                                  GERİ BİLDİRİMİN ARAŞTIRILMASI VE ÇÖZÜMÜNÜN SAĞLANMASI
                                </strong>
                              </Card.Title>
                              <Row>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Select
                                      className="form-control"
                                      aria-label="select"
                                      value={detayRow1?.XValue}
                                      onChange={(e) => {
                                        setDetayRow1({
                                          ...detayRow1,
                                          XValue: e.target.value,
                                        });
                                      }}
                                    >
                                      {fillSelect(
                                        geriBildirimArastirilmasiveCozumunSaglanmasi
                                      )}
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Control
                                      type="date"
                                      required
                                      value={detayRow1?.Tarih}
                                      onChange={(e) => {
                                        setDetayRow1({
                                          ...detayRow1,
                                          Tarih: e.target.value,
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Control
                                      type="text"
                                      placeholder="Alınan Aksiyon"
                                      required
                                      value={detayRow1?.AlinanAksiyon}
                                      onChange={(e) => {
                                        setDetayRow1({
                                          ...detayRow1,
                                          AlinanAksiyon: e.target.value,
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Button
                                    onClick={(e) => satirEkle(detayRow1)}
                                    variant="btn btn-primary mw100 vh100"
                                  >
                                    +EKLE
                                  </Button>
                                </Col>
                              </Row>
                              {detayRow.length > -1 ? (
                                detayRow?.map((elem, index) => {
                                  //console.log(elem);
                                  return elem.XKey ==
                                    "GERİ BİLDİRİMİN ARAŞTIRILMASI VE ÇÖZÜMÜNÜN SAĞLANMASI" ? (
                                    <Container>
                                      <Row
                                        key={index}
                                        className="border-top border-dark mt-3 pt-2"
                                      >
                                        <Col>
                                          <small>{elem.XValue}</small>
                                        </Col>
                                        <Col>
                                          <small>
                                            {elem.Tarih?.split("T")[0]}
                                          </small>
                                        </Col>
                                        <Col>
                                          <small>{elem.AlinanAksiyon}</small>
                                        </Col>
                                        <Col>
                                          <Button
                                            onClick={(e) => detaySil(elem.Id)}
                                            className="btn btn-danger mw100"
                                            variant="danger"
                                            size="sm"
                                          >
                                            sil
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Container>
                                  ) : (
                                    <></>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-md-12">
                          <Card>
                            <Card.Body>
                              <Card.Title>
                                <strong>
                                  ÇÖZÜMÜN GERİ BİLDİRİM SAHİBİNE BİLDİRİLMESİ
                                </strong>
                              </Card.Title>
                              <Row>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Select
                                      className="form-control"
                                      aria-label="select"
                                      value={detayRow2?.XValue}
                                      onChange={(e) => {
                                        setDetayRow2({
                                          ...detayRow2,
                                          XValue: e.target.value,
                                        });
                                      }}
                                    >
                                      {fillSelect(
                                        cozumunGeriBildirimSahibineBildirilmesi
                                      )}
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Control
                                      type="date"
                                      required
                                      value={detayRow2?.Tarih}
                                      onChange={(e) => {
                                        setDetayRow2({
                                          ...detayRow2,
                                          Tarih: e.target.value,
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Control
                                      type="text"
                                      placeholder="Alınan Aksiyon"
                                      required
                                      value={detayRow2?.AlinanAksiyon}
                                      onChange={(e) => {
                                        setDetayRow2({
                                          ...detayRow2,
                                          AlinanAksiyon: e.target.value,
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Button
                                    onClick={(e) => satirEkle(detayRow2)}
                                    variant="btn btn-primary mw100 vh100"
                                  >
                                    +EKLE
                                  </Button>
                                </Col>
                              </Row>
                              {detayRow.length > -1 ? (
                                detayRow?.map((elem, index) => {
                                  //console.log(elem);
                                  return elem.XKey ==
                                    "ÇÖZÜMÜN GERİ BİLDİRİM SAHİBİNE BİLDİRİLMESİ" ? (
                                    <Container>
                                      <Row
                                        key={index}
                                        className="border-top border-dark mt-3 pt-2"
                                      >
                                        <Col>
                                          <small>{elem.XValue}</small>
                                        </Col>
                                        <Col>
                                          <small>
                                            {elem.Tarih?.split("T")[0]}
                                          </small>
                                        </Col>
                                        <Col>
                                          <small>{elem.AlinanAksiyon}</small>
                                        </Col>
                                        <Col>
                                          <Button
                                            onClick={(e) => detaySil(elem.Id)}
                                            className="btn btn-danger mw100"
                                            variant="danger"
                                            size="sm"
                                          >
                                            sil
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Container>
                                  ) : (
                                    <></>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-md-12">
                          <Card>
                            <Card.Body>
                              <Card.Title>
                                <strong>
                                  GERİ BİLDİRİM SAHİBİNİN ÇÖZÜM SÜRECİNDEN
                                  MEMNUNİYETİ
                                </strong>
                              </Card.Title>
                              <Row>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Select
                                      className="form-control"
                                      aria-label="select"
                                      value={detayRow3?.XValue}
                                      onChange={(e) => {
                                        setDetayRow3({
                                          ...detayRow3,
                                          XValue: e.target.value,
                                          AlinanAksiyon: e.target.value,
                                        });
                                      }}
                                    >
                                      {fillSelect(
                                        geriBildirimSahibininCozumSurecindenMemnuniyeti
                                      )}
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Control
                                      type="date"
                                      required
                                      value={detayRow3?.Tarih}
                                      onChange={(e) => {
                                        setDetayRow3({
                                          ...detayRow3,
                                          Tarih: e.target.value,
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Control
                                      type="text"
                                      disabled
                                      placeholder="Alınan Aksiyon"
                                      value={detayRow3?.XValue}
                                      onChange={(e) => {
                                        setDetayRow3({
                                          ...detayRow3,
                                          AlinanAksiyon: detayRow3?.XValue,
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                  {/* <Form.Group className="mb-3">
                                    <Form.Control
                                      type="text"
                                      disabled
                                      placeholder="Alınan Aksiyon"
                                      value={detayRow3?.AlinanAksiyon}
                                      onChange={(e) => {
                                        setDetayRow3({
                                          ...detayRow3,
                                          AlinanAksiyon: "-",
                                        });
                                      }}
                                    />
                                  </Form.Group> */}
                                </Col>
                                <Col>
                                  <Button
                                    onClick={(e) => satirEkle(detayRow3)}
                                    variant="btn btn-primary mw100 vh100"
                                  >
                                    +KAYDET
                                  </Button>
                                </Col>
                              </Row>
                              {detayRow.length > -1 ? (
                                detayRow?.map((elem, index) => {
                                  //console.log(elem);
                                  return elem.XKey ==
                                    "GERİ BİLDİRİM SAHİBİNİN ÇÖZÜM SÜRECİNDEN MEMNUNİYETİ" ? (
                                    <Container>
                                      <Row
                                        key={index}
                                        className="border-top border-dark mt-3 pt-2"
                                      >
                                        <Col>
                                          <small>{elem.XValue}</small>
                                        </Col>
                                        <Col>
                                          <small>
                                            {elem.Tarih?.split("T")[0]}
                                          </small>
                                        </Col>
                                        <Col>
                                          <small>{elem.AlinanAksiyon}</small>
                                        </Col>
                                        <Col>
                                          <Button
                                            onClick={(e) => detaySil(elem.Id)}
                                            className="btn btn-danger mw100"
                                            variant="danger"
                                            size="sm"
                                          >
                                            sil
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Container>
                                  ) : (
                                    <></>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-md-12">
                          <Card>
                            <Card.Body>
                              <Card.Title>
                                <strong>GERİ BİLDİRİM DURUMU</strong>
                              </Card.Title>
                              <Row>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Select
                                      className="form-control"
                                      aria-label="select"
                                      value={detayRow4?.XValue}
                                      onChange={(e) => {
                                        setDetayRow4({
                                          ...detayRow4,
                                          XValue: e.target.value,
                                          AlinanAksiyon: e.target.value,
                                        });
                                        
                                      }}
                                    >
                                      {fillSelect(geriBildirimDurumu)}
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Control
                                      type="date"
                                      required
                                      value={detayRow4?.Tarih}
                                      onChange={(e) => {
                                        setDetayRow4({
                                          ...detayRow4,
                                          Tarih: e.target.value,
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group className="mb-3">
                                    <Form.Control
                                      type="text"
                                      disabled
                                      placeholder="Alınan Aksiyon"
                                      value={detayRow4?.AlinanAksiyon}
                                      // onChange={(e) => {
                                      //   setDetayRow4({
                                      //     ...detayRow4,
                                      //     AlinanAksiyon: detayRow4?.XValue,
                                      //   });
                                      // }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Button
                                    onClick={(e) => satirEkle(detayRow4)}
                                    variant="btn btn-primary mw100 vh100"
                                  >
                                    +KAYDET
                                  </Button>
                                </Col>
                              </Row>
                              {detayRow.length > -1 ? (
                                detayRow?.map((elem, index) => {
                                  //console.log(elem);
                                  return elem.XKey == "GERİ BİLDİRİM DURUMU" ? (
                                    <Container>
                                      <Row
                                        key={index}
                                        className="border-top border-dark mt-3 pt-2"
                                      >
                                        <Col>
                                          <small>{elem.XValue}</small>
                                        </Col>
                                        <Col>
                                          <small>
                                            {elem.Tarih?.split("T")[0]}
                                          </small>
                                        </Col>
                                        <Col>
                                          <small>{elem.AlinanAksiyon}</small>
                                        </Col>
                                        <Col>
                                          <Button
                                            onClick={(e) => detaySil(elem.Id)}
                                            className="btn btn-danger mw100"
                                            variant="danger"
                                            size="sm"
                                          >
                                            sil
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Container>
                                  ) : (
                                    <></>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Container>
                  ) : (
                    <></>
                  )}
                </Form>
              </Modal.Body>
              <Row>
                <Col>
                  {guncelle ? (
                    <Button
                      onClick={formGuncelle}
                      variant="btn btn-primary mw50"
                    >
                      Güncelle
                    </Button>
                  ) : (
                    <Button
                      id="formKaydet"
                      onClick={formKaydet}
                      variant="btn btn-primary mw50"
                    >
                      Kaydet
                    </Button>
                  )}
                  <Button
                    onClick={() => modalKapat()}
                    variant="btn btn-secondary mw50"
                  >
                    Kapat
                  </Button>
                </Col>
              </Row>
            </Modal>
          ) : (
            <></>
          )}
        </Row>
      </Container>
    </>
  );
}

export default UyeTalep;
