import React from "react";

// react-bootstrap components
import NotificationAlert from "react-notification-alert";
import { variables } from "helpers/Variables";
import { useHistory } from "react-router-dom";
import {
  Button,
  OverlayTrigger,
  Card,
  Modal,
  Form,
  Table,
  Container,
  Tooltip,
  ButtonGroup,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";
import { gecenGun, gecenGunDakika, kalanGun } from "../helpers/generic";

function YkmkGerceklesenIslem() {
  const history = useHistory();
  const [data, setData] = React.useState([]);
  const initialForm = {
    Tip: 1,
    YilBas: new Date().getFullYear(),
    YilBit: new Date().getFullYear(),
  };
  const [form, setForm] = React.useState(initialForm);
  const getYears = () => {
    var indents = [];
    for (var i = new Date().getFullYear(); i > 2008; i--) {
      indents.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return indents;
  };
  
  const buyil = getYears();
  const [komiteler, setKomiteler] = React.useState([]);
  const token = secureLocalStorage.getItem("token");
  const notificationAlertRef = React.useRef(null);
  const notify = (place, textt, typee) => {
    var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{textt}</div>
        </div>
      ),
      type: typee ?? type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const getData = async () => {
    const istek = await fetch(
      variables.API_URL + "page/YkMkEylemGerektirenlerGet",
      {
        method: "get",
        headers: {
          Accept: "application/json, text/plain, */*",
          Tad: "YkMkEylemGerektirenlerGet",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (istek.ok) {
      setData(await istek.json());
    } else {
      let txt = await istek.text();
      alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
      history.push("/admin/giris");
    }
  };
  const addFormData = (key, value) => {
    const ff = structuredClone(form);
    // if (ff.Tip == 3 ||(key=='Tip' && value=='3')) {
    //   ff.Komite = "1";
    // } else {
    //   delete ff.Komite;
    // }
    ff[key] = value;
    ff.tad = "YkMkEylemGerektirenlerGet";
    if (key == "Komite") {
      ff.Komite = value;
    }
    setForm(ff);
    //birime bağlı kategorileri getir
    console.log(ff,key,value);
    (async () => {
      const rawResponse = await fetch(variables.API_URL + "Page", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          Tad: "YkMkEylemGerektirenlerGet",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(ff),
      });
      if (!rawResponse.ok) {
        alert("İşlem sırasında hata! Yetkiniz olmayabilir.");
      } else {
        const content = await rawResponse.json();
        setData(content); // Gelen veriyi data state'ine set ediyoruz
      }
    })();
  };

  const getKomiteler = async () => {
    try {
      const response = await fetch(
        variables.API_URL + "page/ToplantiTipleriKomite",
        {
          method: "get",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Tad: "ToplantiTipleriKomite",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setKomiteler(data);
      } else {
        let txt = await response.text();
        alert(txt == "" ? "İşlem sırasında hata! Yetkiniz olmayabilir." : txt);
        throw new Error("Komite listesi alınamadı");
      }
    } catch (error) {
      notify(
        "tc",
        "Komite listesi yüklenirken hata oluştu: " + error.message,
        "danger"
      );
    }
  };
  React.useEffect(() => {
    getKomiteler();
    getData();
  }, []);
  return (
    <>
      <Container fluid>
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                {/* <Card.Title as="h4">Performans Gelişimlerim</Card.Title> */}
                <Button variant="secondary">
                  Yk Mk Eylem Gerektirenler / Gerçekleşen Eylemler
                </Button>
                <span> {data?.length + " adet kayıt bulundu!"}</span>
                {/* <Button onClick={() => setShowModal(true)}>EKLE</Button> */}
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Yıl Başlangıç:</b>
                      </Form.Label>
                      <Form.Select
                        className="form-control"
                        aria-label="select"
                        value={form?.YilBas}
                        onChange={(e) => {
                          addFormData("YilBas", e.target.value);
                        }}
                      >
                        {buyil}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Yıl Bitiş:</b>
                      </Form.Label>
                      <Form.Select
                        className="form-control"
                        aria-label="select"
                        value={form?.YilBit}
                        onChange={(e) => {
                          addFormData("YilBit", e.target.value);
                        }}
                      >
                        {buyil}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Yk/Mk/Komite:</b>
                      </Form.Label>
                      <Form.Select
                        className="form-control"
                        aria-label="select"
                        value={form?.Tip}
                        onChange={(e) => {
                          addFormData("Tip", e.target.value);
                        }}
                      >
                        <option key={1} value={1}>
                          {"Yönetim Kurulu"}
                        </option>
                        <option key={2} value={2}>
                          {"Meclis"}
                        </option>
                        <option key={3} value={3}>
                          {"Komite"}
                        </option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {form?.Tip == "3" ? (
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Komite:</b>
                        </Form.Label>
                        <Form.Select
                          className="form-control"
                          aria-label="select"
                          value={form?.Komite}
                          onChange={(e) => {
                            addFormData("Komite", e.target.value);
                          }}
                        >
                          <option key={895659} value={0}>
                              {"HEPSİ"}
                            </option>
                          {komiteler.map((komite) => (
                            <option key={komite.ToplantiTipId} value={komite.ToplantiTipId}>
                              {komite.Tipi}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th>ToplamKararSayisi</th>
                      <th>EylemGerektirenSayisi</th>
                      <th>GerceklesenSayisi</th>
                      <th>GerceklesmeyenSayisi</th>
                      <th>DevamEden</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > -1 ? (
                      data.map((elem, index) => {
                        return (
                          <tr key={index}>
                            {/* <td>{elem.EgitimId}{" "}</td> */}
                            <td>{elem?.ToplamKararSayisi}</td>
                            <td>{elem?.EylemGerektirenSayisi}</td>
                            <td>{elem?.GerceklesenSayisi}</td>
                            <td>{elem?.GerceklesmeyenSayisi}</td>
                            <td>{elem?.DevamEden}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>
                          {data?.length + " adet kayıt bulundu!"}
                          <Spinner
                            as="span"
                            animation="border"
                            size="xl"
                            role="status"
                            aria-hidden="true"
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          
        </Row>
      </Container>
    </>
  );
}

export default YkmkGerceklesenIslem;
