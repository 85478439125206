export const variables = {
    API_URL: "http://api.etonet.org.tr/api/",
    THIS_APP_URL: "http://etomanager.etonet.org.tr/admin/egitimlerim/",
    //API_URL: "http://localhost:5826/api/",
    //THIS_APP_URL: "http://localhost:3000/admin/egitimlerim/",
    // API_URL: "http://api.etonet.org.tr/api/",
    // THIS_APP_URL: "http://etomanager.etonet.org.tr/admin/egitimlerim/",
    PHOTO_URL: "http://localhost:5826/Photos/"
}


